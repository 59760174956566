<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-form class="multi-col-validation">
      <v-card-text class="pt-5">
        <v-row>
          <!--<v-col cols="12">
            <v-textarea
              v-model="accountData.bio"
              outlined
              rows="3"
              label="Bio"
            ></v-textarea>
          </v-col>-->

          <v-col
            cols="12"
            md="6"
          >
            <vc-date-picker
              v-model="accountData.birthday"
              outlined
              dense
              mode="date"
              :model-config="modelConfig"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <v-text-field
                  :label="$t('lbl.birthday')"
                  outlined
                  dense
                  :value="inputValue"
                  v-on="inputEvents"
                >
                </v-text-field>
              </template>
            </vc-date-picker>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="accountData.country"
              outlined
              dense
              :label="$t('lbl.country')"
              item-text="name"
              item-value="name"
              :items="countries"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <p class="text--primary mt-n3 mb-2">
              {{ $t('lbl.sexo') }}
            </p>
            <v-radio-group
              v-model="accountData.sex"
              row
              class="mt-0"
              hide-details
            >
              <v-radio
                value="fem"
                :label="$t('lbl.sexFem')"
              >
              </v-radio>
              <v-radio
                value="male"
                :label="$t('lbl.sexMasc')"
              >
              </v-radio>
              <v-radio
                value="other"
                :label="$t('lbl.sexOther')"
              >
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <v-btn
          color="primary"
          class="me-3 mt-3"
          :loading="loading"
          @click="save"
        >
          {{ $t('btn.acceptChange') }}
        </v-btn>
        <v-btn
          outlined
          class="mt-3"
          color="secondary"
          type="reset"
          @click="cancele"
        >
          {{ $t('btn.cancele') }}
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import {
  mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline, mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate, mdiCamera,
} from '@mdi/js'

export default {
  props: {
    accountData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      avatar: null,
      previewImage: null,
      changeAvatar: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCamera,
        mdiKeyOutline,
        mdiLockOpenOutline,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      editUser: false,
      model: {
        avatar: null,
        id: null,
        name: null,
        apellidos: null,
        email: null,
        role_id: null,
        active: true,
      },
      password: '',
      confirmation_password: '',
      permisos: [],
      permisosItem: [],
      loading: false,
      onlyShow: false,
      rules: {
        min: v => v.length >= 6 || this.$t('lbl.min6'),
      },
      show: false,
      status: [],
      emailOrigin: null,
      isCurrentPasswordVisible: false,
      isNewPasswordVisible: false,
      isCPasswordVisible: false,
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,
      languajes: [],
      countries: [],
    }
  },
  created() {
    this.getCountries()
    this.languajes.push({ name: 'Español', code: 'es' })
    this.languajes.push({ name: 'English', code: 'en' })
  },
  methods: {
    getCountries() {
      this.axios
        .get('country_list?per_page=1000')
        .then(res => {
          this.countries = res.data.data

          /* this.axios
            .get('https://restcountries.com/v2/all?fields=name,currencies,alpha2Code')
            .then(res => {
              this.countries = res.data
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => this.isLoading = false) */
        })
        .finally(() => {
          this.getCurrencies()
        })

      /* this.axios
        .get('https://restcountries.com/v2/all?fields=name,currencies,alpha2Code')
        .then(res => {
          this.countries = res.data
          this.countries.forEach(element => {
            if (element.alpha2Code === this.paisUser.country_code) {
              this.model.country_facturation = element.name
            }
            const mone = element.currencies
            if (mone) {
              mone.forEach(mmm => {
                if (!this.monedas.includes(mmm.code)) {
                  this.monedas.push(mmm.code)
                }
              })
            }
          })
        }) */
    },
    save() {
      if (this.accountData.birthday) {
        this.loading = true
        const json = {
          birthday: this.accountData.birthday,
          phone: this.accountData.phone,
          country: this.accountData.country,
          sex: this.accountData.sex,
        }

        this.axios
          .post('profile-data', json, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            // eslint-disable-next-line no-empty
            if (response.data.success === false) { } else {
              this.$toast.success(this.$t('msg.infoSuccess'))
            }
          })
        // eslint-disable-next-line no-return-assign
          .finally(() => this.loading = false)
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    cancele() {
      this.$router.go(-1)
    },
  },
}
</script>
