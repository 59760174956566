<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-form class="multi-col-validation">
      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="12"
            md="2"
          >
            <v-btn
              color="primary"
              class="me-3 mt-3"
              :loading="loading"
              :disabled="verifyChanges"
              @click="save"
            >
              {{ $t('btn.acceptChange') }}
            </v-btn>
          </v-col>
        </v-row>
        <!--GEOTAG-->
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <b>{{ $t('lbl.geolocalizar') }}</b>
              </v-col>
              <v-col
                cols="12"
                md="1"
              >
              </v-col>
              <v-col
                cols="12"
                md="1"
              >
                <v-btn
                  icon
                  @click="showItemGeolocalizar()"
                >
                  <v-icon>
                    {{ showRowGeolocalizar ? icons.mdiMenuDown : icons.mdiMenuRight }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="showRowGeolocalizar">
              <v-col
                cols="12"
                md="12"
              >
                <GeoTag
                  :zoom="zoom"
                  style="height: 350px;"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="geotag.longitud"
                  disabled
                  :label="$t('lbl.longitud')"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="geotag.latitud"
                  disabled
                  :label="$t('lbl.latitud')"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="mb-2 mt-2"></v-divider>

        <!--CONTACTS-->
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <b>{{ $t('lbl.contacts') }}</b>
              </v-col>
              <v-col
                cols="12"
                md="1"
              >
              </v-col>
              <v-col
                cols="12"
                md="1"
              >
                <v-btn
                  icon
                  @click="showItemContacts()"
                >
                  <v-icon>
                    {{ showRowContacts ? icons.mdiMenuDown : icons.mdiMenuRight }}
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col
                v-if="showRowContacts"
                cols="12"
                md="1"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mt-1 mx-2"
                      fab
                      dark
                      x-small
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="addContactsAfiliados"
                    >
                      <v-icon small>
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.insert') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row v-if="showRowContacts">
              <v-col
                cols="12"
                md="12"
              >
                <v-row
                  v-for="(contact, indC) in contactsAfiliados"
                  :key="indC"
                >
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <Contact
                      :pos="indC"
                      :contact="contact"
                      :product-item="nom_tipo_products_id"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-row
                      v-for="(email, indE) in contact.emails"
                      :key="indE"
                    >
                      <Email
                        :pos-contact="indC"
                        :pos="indE"
                        :email="email"
                      />
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-row
                      v-for="(phone, indT) in contact.telefonos"
                      :key="indT"
                    >
                      <Telefono
                        :pos-contact="indC"
                        :pos="indT"
                        :telefono="phone"
                      />
                    </v-row>
                  </v-col>
                  <v-col
                    v-if="indC !== contactsAfiliados.length - 1"
                    cols="12"
                    md="12"
                  >
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="mb-2 mt-2"></v-divider>

        <!--ABOUTS-->
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <b>{{ $t('lbl.about') }}</b>
              </v-col>
              <v-col
                cols="12"
                md="1"
              >
              </v-col>
              <v-col
                cols="12"
                md="1"
              >
                <v-btn
                  icon
                  @click="showItemAbout()"
                >
                  <v-icon>
                    {{ showRowAbout ? icons.mdiMenuDown : icons.mdiMenuRight }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="showRowAbout">
              <v-col
                cols="12"
                md="12"
              >
                <CMS />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiKeyOutline,
  mdiLockOpenOutline,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiCamera,
  mdiMenuDown,
  mdiMenuRight,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import GeoTag from '@/views/utils/geolocalization/GeoTag.vue'
// eslint-disable-next-line import/no-unresolved
import CMS from '@/views/utils/cms/CMS.vue'

// eslint-disable-next-line import/no-unresolved
import Contact from '@/views/user_register/utils/Contact.vue'
// eslint-disable-next-line import/no-unresolved
import Email from '@/views/user_register/utils/Email.vue'
// eslint-disable-next-line import/no-unresolved
import Telefono from '@/views/user_register/utils/Telefono.vue'

export default {
  components: {
    GeoTag,
    Contact,
    Email,
    Telefono,
    CMS,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      avatar: null,
      previewImage: null,
      changeAvatar: true,
      zoom: 12,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCamera,
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiMenuDown,
        mdiMenuRight,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      editUser: false,
      model: {
        avatar: null,
        id: null,
        name: null,
        apellidos: null,
        email: null,
        role_id: null,
        active: true,
      },
      password: '',
      confirmation_password: '',
      permisos: [],
      permisosItem: [],
      loading: false,
      rules: {
        min: v => v.length >= 6 || this.$t('lbl.min6'),
      },
      show: false,
      status: [],
      emailOrigin: null,
      isCurrentPasswordVisible: false,
      isNewPasswordVisible: false,
      isCPasswordVisible: false,
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,
      languajes: [],
      countries: [],
      showRowGeolocalizar: false,
      showRowContacts: false,
      showRowAbout: false,
      nom_tipo_products_id: [],
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      geotag: state => state.app.geotag,
      contactsAfiliados: state => state.app.contactsAfiliados,
      textEnriquecido: state => state.app.textEnriquecido,
    }),
    verifyChanges() {
      let disabled = true
      const jsonVerify = localStorage.getItem('about-company')

      const jsonCMS = JSON.stringify({
        about: this.textEnriquecido,
        contacts: this.contactsAfiliados,
        geotag: this.geotag,
      })

      if (jsonVerify !== jsonCMS) {
        disabled = false
      }

      return disabled
    },
  },
  created() {
    const jsonCMS = {
      about: null,
      contacts: [],
      geotag: {
        longitud: undefined,
        latitud: undefined,
      },
    }

    if (this.user.company_id) {
      this.updateContactsAfiliados(this.user.company.contacts)
      jsonCMS.contacts = this.user.company.contacts

      this.nom_tipo_products_id = this.user.company.nom_tipo_products_id

      if (this.user.company.cms) {
        if (this.user.company.cms.about) {
          this.updateCMS(this.user.company.cms.about)
          jsonCMS.about = this.user.company.cms.about
        } else {
          this.updateCMS(null)
        }
      } else {
        this.updateCMS(null)
      }

      if (this.user.company.cms) {
        if (this.user.company.cms.geotag) {
          this.updateGeoTag(this.user.company.cms.geotag)
          jsonCMS.geotag = this.user.company.cms.geotag
        } else {
          this.updateGeoTag({ longitud: undefined, latitud: undefined })
        }
      } else {
        this.updateGeoTag({ longitud: undefined, latitud: undefined })
      }
    } else {
      this.updateContactsAfiliados([])
      this.updateGeoTag({ longitud: undefined, latitud: undefined })
      this.updateCMS(null)
    }

    localStorage.setItem('about-company', JSON.stringify(jsonCMS))
  },
  methods: {
    ...mapMutations(['addContactsAfiliados', 'updateContactsAfiliados', 'updateGeoTag', 'updateCMS']),
    showItemGeolocalizar() {
      this.showRowGeolocalizar = !this.showRowGeolocalizar
      this.showRowContacts = false
      this.showRowAbout = false
    },
    showItemContacts() {
      this.showRowContacts = !this.showRowContacts
      this.showRowGeolocalizar = false
      this.showRowAbout = false
    },
    showItemAbout() {
      this.showRowAbout = !this.showRowAbout
      this.showRowGeolocalizar = false
      this.showRowContacts = false
    },
    save() {
      const json = {
        geotag: this.geotag,
        contacts: this.contactsAfiliados,
        about: this.textEnriquecido,
      }

      this.loading = true

      this.axios
        .post(`affiliate/update-cms/${this.user.company_id}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.loading = false
          this.showRowGeolocalizar = false
          this.showRowContacts = false
          this.showRowAbout = false
        })
    },
    cancele() {
      this.$router.go(-1)
    },
  },
}
</script>
